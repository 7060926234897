import { takeLatest } from 'redux-saga/effects'

import Actions from 'redux/actions'
import { getSpecialServicesList as getSpecialServicesListAction } from 'redux/actionCreators/premiumUserAPI/specialServices'
import specialServicesAPI from 'api/premiumUserAPI/specialServicesAPI'

import { getSimpleRequestHandler } from '../../utils'

const getSpecialServicesList = getSimpleRequestHandler<
  typeof specialServicesAPI.getSpecialServicesList,
  ReturnType<typeof getSpecialServicesListAction>
>({
  actionLoading: Actions.GET_SPECIAL_SERVICES_LIST_ASYNC,
  actionSuccess: Actions.GET_SPECIAL_SERVICES_LIST_SUCCESS,
  actionFail: Actions.GET_SPECIAL_SERVICES_LIST_FAIL,
  callApiFn: specialServicesAPI.getSpecialServicesList,
})

export default [takeLatest(Actions.GET_SPECIAL_SERVICES_LIST, getSpecialServicesList)]
