import { GetSpecialServicesListResponse, SpecialServiceFromBE } from 'api/premiumUserAPI/specialServicesAPI'
import { CallAPIAction, Status } from 'api/types'
import { GeneralFailResponse, SpecialServiceStatuses, SpecialServiceTypes } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type SpecialService = {
  id: string
  name: string
  idNumber: string
  service: SpecialServiceTypes
  createdAt: string
  status: SpecialServiceStatuses
  rmName: string
}

type State = {
  data: Array<SpecialService>
  currentPage: number
  lastPage: number
} & Status

const initialState: State = {
  data: [],
  currentPage: 0,
  lastPage: 0,
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = (item: SpecialServiceFromBE) => ({
  id: item.display_id,
  name: item.name,
  idNumber: item.id_number,
  service: item.service,
  createdAt: item.created_at,
  status: item.status,
  rmName: item.relationship_manager_name,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_SPECIAL_SERVICES_LIST_ASYNC,
    Actions.GET_SPECIAL_SERVICES_LIST_SUCCESS,
    Actions.GET_SPECIAL_SERVICES_LIST_FAIL,
    GetSpecialServicesListResponse,
    GeneralFailResponse
  >,
) => {
  switch (action.type) {
    case Actions.GET_SPECIAL_SERVICES_LIST_ASYNC:
    case Actions.GET_SPECIAL_SERVICES_LIST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.GET_SPECIAL_SERVICES_LIST_SUCCESS:
      return {
        ...state,
        ...action.status,
        data: action.payload.data.map(convertResponseToState),
        currentPage: action.payload.current_page,
        lastPage: action.payload.last_page,
      }

    default:
      return state
  }
}
