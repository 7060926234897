import { select, takeLatest } from 'redux-saga/effects'

import {
  getEtoIRequests as getEtoIRequestsAction,
  getEtoIRequest as getEtoIRequestAction,
  rejectEToIOffer as rejectEToIOfferAction,
  acceptEToIOffer as acceptEToIOfferAction,
  payForEToIRequest as payForEToIRequestAction,
  cancelEToIContract as cancelEToIContractAction,
  addFinancialDocuments as addFinancialDocumentsAction,
  eToITransferWorker as eToITransferWorkerAction,
  eToIReplaceWorker as eToIReplaceWorkerAction,
  getIToIRequestsList as getIToIRequestsListAction,
  getIToILaborerList as getIToILaborerListAction,
  checkIToITransferEligibility as checkIToITransferEligibilityAction,
  eToICancelReplacement as eToICancelReplacementAction,
  submitIToITransferRequest as submitIToITransferRequestAction,
  rejectIToIOffer as rejectIToIOfferAction,
  acceptIToIOffer as acceptIToIOfferAction,
  getIToIRequestDetails as getIToIRequestDetailsAction,
  getIToILaborerDetails as getIToILaborerDetailsAction,
  addIToIFinancialDocuments as addIToIFinancialDocumentsAction,
  payForIToIRequest as payForIToIRequestAction,
  getIToIBill as getIToIBillAction,
  resendEToIRequest as resendEToIRequestAction,
  getItoISuggestedCost as getItoISuggestedCostAction,
  getEtoIInvoiceFile as getEtoIInvoiceFileAction,
  getItoIInvoiceFile as getItoIInvoiceFileAction,
  checkItoITransfersCount as checkItoITransfersCountAction,
} from 'redux/actionCreators/newDLTransferAPI'
import newDLTransferAPI from 'api/newDLTransferAPI'
import Actions from 'redux/actions'
import { RootState } from 'redux/rootReducer'
import { history } from 'redux/browserHistory'
import { Routes, routeWithParams } from 'containers/Routes/routePaths'
import { EToIRequestStatus } from 'common/types/commonTypes'
import { getPaymentRoute } from 'common/utils/eToIRequestHelper'
import authManager from 'common/utils/auth/authManager'

import { getSimpleRequestHandler } from '../utils'

const getEtoIRequestsList = getSimpleRequestHandler<
  typeof newDLTransferAPI.getEtoIRequestsList,
  ReturnType<typeof getEtoIRequestsAction>
>({
  actionLoading: Actions.GET_E_TO_I_REQUESTS_ASYNC,
  actionSuccess: Actions.GET_E_TO_I_REQUESTS_SUCCESS,
  actionFail: Actions.GET_E_TO_I_REQUESTS_FAIL,
  callApiFn: newDLTransferAPI.getEtoIRequestsList,
})

function* getEtoIRequestDetailsSaga(action: ReturnType<typeof getEtoIRequestAction>) {
  const getEtoIRequestDetails = getSimpleRequestHandler<
    typeof newDLTransferAPI.getEtoIRequestDetails,
    ReturnType<typeof getEtoIRequestAction>
  >({
    actionLoading: Actions.GET_E_TO_I_REQUEST_DETAILS_ASYNC,
    actionSuccess: Actions.GET_E_TO_I_REQUEST_DETAILS_SUCCESS,
    actionFail: Actions.GET_E_TO_I_REQUEST_DETAILS_FAIL,
    callApiFn: newDLTransferAPI.getEtoIRequestDetails,
  })
  yield getEtoIRequestDetails(action)
  const eToIRequest: RootState['eToIRequest'] = yield select((state: RootState) => state.eToIRequest)
  const route = action.payload.route
  if (eToIRequest.success && route) {
    const requestId = action.payload.params.requestId
    const requestStatus = eToIRequest.request.status
    if (
      route === Routes.SERVICE_TRANSFER_E_TO_I_ACCEPT_ATTACHMENTS &&
      requestStatus === EToIRequestStatus.WAITING_PAYMENT
    ) {
      history.push(routeWithParams(Routes.SERVICE_TRANSFER_E_TO_I_ACCEPT_PAYMENT, { requestId }))
    } else if (
      (route === Routes.SERVICE_TRANSFER_E_TO_I_ACCEPT_ATTACHMENTS &&
        requestStatus !== EToIRequestStatus.WAITING_FINANCIAL_DOCUMENTS) ||
      (route === Routes.SERVICE_TRANSFER_E_TO_I_ACCEPT_PAYMENT && requestStatus !== EToIRequestStatus.WAITING_PAYMENT)
    ) {
      history.push(routeWithParams(Routes.SERVICE_TRANSFER_E_TO_I_REQUEST, { requestId }))
    } else if (
      route === Routes.VIP_SERVICE_TRANSFER_E_TO_I_ACCEPT_ATTACHMENTS &&
      requestStatus === EToIRequestStatus.WAITING_PAYMENT
    ) {
      history.push(routeWithParams(Routes.VIP_SERVICE_TRANSFER_E_TO_I_ACCEPT_PAYMENT, { requestId }))
    } else if (
      (route === Routes.VIP_SERVICE_TRANSFER_E_TO_I_ACCEPT_ATTACHMENTS &&
        requestStatus !== EToIRequestStatus.WAITING_FINANCIAL_DOCUMENTS) ||
      (route === Routes.VIP_SERVICE_TRANSFER_E_TO_I_ACCEPT_PAYMENT &&
        requestStatus !== EToIRequestStatus.WAITING_PAYMENT)
    ) {
      history.push(routeWithParams(Routes.VIP_SERVICE_TRANSFER_E_TO_I_REQUEST, { requestId }))
    }
  }
}

const rejectEToIOffer = getSimpleRequestHandler<
  typeof newDLTransferAPI.rejectEToIOffer,
  ReturnType<typeof rejectEToIOfferAction>
>({
  actionLoading: Actions.REJECT_E_TO_I_OFFER_ASYNC,
  actionSuccess: Actions.REJECT_E_TO_I_OFFER_SUCCESS,
  actionFail: Actions.REJECT_E_TO_I_OFFER_FAIL,
  callApiFn: newDLTransferAPI.rejectEToIOffer,
})

function* acceptEToIOfferSaga(action: ReturnType<typeof acceptEToIOfferAction>) {
  const acceptEToIOffer = getSimpleRequestHandler<
    typeof newDLTransferAPI.acceptEToIOffer,
    ReturnType<typeof acceptEToIOfferAction>
  >({
    actionLoading: Actions.ACCEPT_E_TO_I_OFFER_ASYNC,
    actionSuccess: Actions.ACCEPT_E_TO_I_OFFER_SUCCESS,
    actionFail: Actions.ACCEPT_E_TO_I_OFFER_FAIL,
    callApiFn: newDLTransferAPI.acceptEToIOffer,
  })
  yield acceptEToIOffer(action)
  const acceptEToIOfferStatus: RootState['acceptEToIOfferStatus'] = yield select(
    (state: RootState) => state.acceptEToIOfferStatus,
  )
  if (acceptEToIOfferStatus.success) {
    history.push(
      routeWithParams(getPaymentRoute(acceptEToIOfferStatus.requestStatus), { requestId: action.payload.params.id }),
    )
  }
}

const addFinancialDocuments = getSimpleRequestHandler<
  typeof newDLTransferAPI.addFinancialDocuments,
  ReturnType<typeof addFinancialDocumentsAction>
>({
  actionLoading: Actions.ADD_E_TO_I_FINANCIAL_DOCUMENTS_ASYNC,
  actionSuccess: Actions.ADD_E_TO_I_FINANCIAL_DOCUMENTS_SUCCESS,
  actionFail: Actions.ADD_E_TO_I_FINANCIAL_DOCUMENTS_FAIL,
  callApiFn: newDLTransferAPI.addFinancialDocuments,
})

function* payForEToIRequestSaga(action: ReturnType<typeof payForEToIRequestAction>) {
  const payForEToIRequest = getSimpleRequestHandler<
    typeof newDLTransferAPI.payForEToIRequest,
    ReturnType<typeof payForEToIRequestAction>
  >({
    actionLoading: Actions.PAY_FOR_E_TO_I_REQUEST_ASYNC,
    actionSuccess: Actions.PAY_FOR_E_TO_I_REQUEST_SUCCESS,
    actionFail: Actions.PAY_FOR_E_TO_I_REQUEST_FAIL,
    callApiFn: newDLTransferAPI.payForEToIRequest,
  })
  yield payForEToIRequest(action)
  const payForEToIRequestStatus: RootState['payForEToIRequestStatus'] = yield select(
    (state: RootState) => state.payForEToIRequestStatus,
  )
  const formSubmitRedirect = action.payload.params.formSubmitRedirect
  if (payForEToIRequestStatus.success && formSubmitRedirect) {
    formSubmitRedirect()
  }
}

const cancelEToIContract = getSimpleRequestHandler<
  typeof newDLTransferAPI.cancelEToIContract,
  ReturnType<typeof cancelEToIContractAction>
>({
  actionLoading: Actions.CANCEL_E_TO_I_CONTRACT_ASYNC,
  actionSuccess: Actions.CANCEL_E_TO_I_CONTRACT_SUCCESS,
  actionFail: Actions.CANCEL_E_TO_I_CONTRACT_FAIL,
  callApiFn: newDLTransferAPI.cancelEToIContract,
})

const eToITransferWorker = getSimpleRequestHandler<
  typeof newDLTransferAPI.transferWorker,
  ReturnType<typeof eToITransferWorkerAction>
>({
  actionLoading: Actions.E_TO_I_TRANSFER_WORKER_ASYNC,
  actionSuccess: Actions.E_TO_I_TRANSFER_WORKER_SUCCESS,
  actionFail: Actions.E_TO_I_TRANSFER_WORKER_FAIL,
  callApiFn: newDLTransferAPI.transferWorker,
})

const eToIReplaceWorker = getSimpleRequestHandler<
  typeof newDLTransferAPI.replaceWorker,
  ReturnType<typeof eToIReplaceWorkerAction>
>({
  actionLoading: Actions.E_TO_I_REPLACE_WORKER_ASYNC,
  actionSuccess: Actions.E_TO_I_REPLACE_WORKER_SUCCESS,
  actionFail: Actions.E_TO_I_REPLACE_WORKER_FAIL,
  callApiFn: newDLTransferAPI.replaceWorker,
})

const getIToIRequestsList = getSimpleRequestHandler<
  typeof newDLTransferAPI.getIToIRequestsList,
  ReturnType<typeof getIToIRequestsListAction>
>({
  actionLoading: Actions.GET_I_TO_I_REQUESTS_LIST_ASYNC,
  actionSuccess: Actions.GET_I_TO_I_REQUESTS_LIST_SUCCESS,
  actionFail: Actions.GET_I_TO_I_REQUESTS_LIST_FAIL,
  callApiFn: newDLTransferAPI.getIToIRequestsList,
})

const getIToILaborerList = getSimpleRequestHandler<
  typeof newDLTransferAPI.getIToILaborerList,
  ReturnType<typeof getIToILaborerListAction>
>({
  actionLoading: Actions.GET_I_TO_I_LABORER_LIST_ASYNC,
  actionSuccess: Actions.GET_I_TO_I_LABORER_LIST_SUCCESS,
  actionFail: Actions.GET_I_TO_I_LABORER_LIST_FAIL,
  callApiFn: newDLTransferAPI.getIToILaborerList,
})

const iToITransferEligibility = getSimpleRequestHandler<
  typeof newDLTransferAPI.checkIToIElegibility,
  ReturnType<typeof checkIToITransferEligibilityAction>
>({
  actionLoading: Actions.CHECK_I_TO_I_TRANSFER_ELIGIBILITY_ASYNC,
  actionSuccess: Actions.CHECK_I_TO_I_TRANSFER_ELIGIBILITY_SUCCESS,
  actionFail: Actions.CHECK_I_TO_I_TRANSFER_ELIGIBILITY_FAIL,
  callApiFn: newDLTransferAPI.checkIToIElegibility,
})

const eToICancelReplacement = getSimpleRequestHandler<
  typeof newDLTransferAPI.cancelReplacement,
  ReturnType<typeof eToICancelReplacementAction>
>({
  actionLoading: Actions.E_TO_I_CANCEL_REPLACEMENT_ASYNC,
  actionSuccess: Actions.E_TO_I_CANCEL_REPLACEMENT_SUCCESS,
  actionFail: Actions.E_TO_I_CANCEL_REPLACEMENT_FAIL,
  callApiFn: newDLTransferAPI.cancelReplacement,
})

const submitIToITransferRequest = getSimpleRequestHandler<
  typeof newDLTransferAPI.submitIToITransferRequest,
  ReturnType<typeof submitIToITransferRequestAction>
>({
  actionLoading: Actions.SUBMIT_I_TO_I_TRANSFER_REQUEST_ASYNC,
  actionSuccess: Actions.SUBMIT_I_TO_I_TRANSFER_REQUEST_SUCCESS,
  actionFail: Actions.SUBMIT_I_TO_I_TRANSFER_REQUEST_FAIL,
  callApiFn: newDLTransferAPI.submitIToITransferRequest,
})

const rejectIToIOffer = getSimpleRequestHandler<
  typeof newDLTransferAPI.rejectIToIOffer,
  ReturnType<typeof rejectIToIOfferAction>
>({
  actionLoading: Actions.REJECT_I_TO_I_OFFER_ASYNC,
  actionSuccess: Actions.REJECT_I_TO_I_OFFER_SUCCESS,
  actionFail: Actions.REJECT_I_TO_I_OFFER_FAIL,
  callApiFn: newDLTransferAPI.rejectIToIOffer,
})

const acceptIToIOffer = getSimpleRequestHandler<
  typeof newDLTransferAPI.acceptIToIOffer,
  ReturnType<typeof acceptIToIOfferAction>
>({
  actionLoading: Actions.ACCEPT_I_TO_I_OFFER_ASYNC,
  actionSuccess: Actions.ACCEPT_I_TO_I_OFFER_SUCCESS,
  actionFail: Actions.ACCEPT_I_TO_I_OFFER_FAIL,
  callApiFn: newDLTransferAPI.acceptIToIOffer,
})

const getIToIRequestDetails = getSimpleRequestHandler<
  typeof newDLTransferAPI.getIToIRequestDetails,
  ReturnType<typeof getIToIRequestDetailsAction>
>({
  actionLoading: Actions.GET_I_TO_I_REQUEST_DETAILS_ASYNC,
  actionSuccess: Actions.GET_I_TO_I_REQUEST_DETAILS_SUCCESS,
  actionFail: Actions.GET_I_TO_I_REQUEST_DETAILS_FAIL,
  callApiFn: newDLTransferAPI.getIToIRequestDetails,
})

const getIToILaborerDetails = getSimpleRequestHandler<
  typeof newDLTransferAPI.getIToILaborerDetails,
  ReturnType<typeof getIToILaborerDetailsAction>
>({
  actionLoading: Actions.GET_I_TO_I_LABORER_DETAILS_ASYNC,
  actionSuccess: Actions.GET_I_TO_I_LABORER_DETAILS_SUCCESS,
  actionFail: Actions.GET_I_TO_I_LABORER_DETAILS_FAIL,
  callApiFn: newDLTransferAPI.getIToILaborerDetails,
})

const addIToIFinancialDocuments = getSimpleRequestHandler<
  typeof newDLTransferAPI.addIToIFinancialDocuments,
  ReturnType<typeof addIToIFinancialDocumentsAction>
>({
  actionLoading: Actions.ADD_I_TO_I_FINANCIAL_DOCUMENTS_ASYNC,
  actionSuccess: Actions.ADD_I_TO_I_FINANCIAL_DOCUMENTS_SUCCESS,
  actionFail: Actions.ADD_I_TO_I_FINANCIAL_DOCUMENTS_FAIL,
  callApiFn: newDLTransferAPI.addIToIFinancialDocuments,
})

function* payForIToIRequestSaga(action: ReturnType<typeof payForIToIRequestAction>) {
  const payForIToIRequest = getSimpleRequestHandler<
    typeof newDLTransferAPI.payForIToIRequest,
    ReturnType<typeof payForIToIRequestAction>
  >({
    actionLoading: Actions.PAY_FOR_I_TO_I_REQUEST_ASYNC,
    actionSuccess: Actions.PAY_FOR_I_TO_I_REQUEST_SUCCESS,
    actionFail: Actions.PAY_FOR_I_TO_I_REQUEST_FAIL,
    callApiFn: newDLTransferAPI.payForIToIRequest,
  })
  yield payForIToIRequest(action)
  const payForIToIRequestStatus: RootState['payForIToIRequestStatus'] = yield select(
    (state: RootState) => state.payForIToIRequestStatus,
  )
  const formSubmitRedirect = action.payload.params.formSubmitRedirect
  if (payForIToIRequestStatus.success && !formSubmitRedirect) {
    history.push(
      routeWithParams(
        authManager.isVipUser() ? Routes.VIP_SERVICE_TRANSFER_I_TO_I_PAYMENT : Routes.SERVICE_TRANSFER_I_TO_I_PAYMENT,
        {
          requestId: payForIToIRequestStatus.breakdownBill.transferRequestId.toString(),
        },
      ),
    )
  }
  if (payForIToIRequestStatus.success && formSubmitRedirect) {
    formSubmitRedirect()
  }
}

const getIToIBill = getSimpleRequestHandler<typeof newDLTransferAPI.getIToIBill, ReturnType<typeof getIToIBillAction>>(
  {
    actionLoading: Actions.GET_I_TO_I_BILL_ASYNC,
    actionSuccess: Actions.GET_I_TO_I_BILL_SUCCESS,
    actionFail: Actions.GET_I_TO_I_BILL_FAIL,
    callApiFn: newDLTransferAPI.getIToIBill,
  },
)

const resendEToIRequest = getSimpleRequestHandler<
  typeof newDLTransferAPI.resendEToIRequest,
  ReturnType<typeof resendEToIRequestAction>
>({
  actionLoading: Actions.RESEND_E_TO_I_REQUEST_ASYNC,
  actionSuccess: Actions.RESEND_E_TO_I_REQUEST_SUCCESS,
  actionFail: Actions.RESEND_E_TO_I_REQUEST_FAIL,
  callApiFn: newDLTransferAPI.resendEToIRequest,
})

const getItoISuggestedCost = getSimpleRequestHandler<
  typeof newDLTransferAPI.getItoISuggestedCost,
  ReturnType<typeof getItoISuggestedCostAction>
>({
  actionLoading: Actions.GET_I_TO_I_SUGGESTED_COST_ASYNC,
  actionSuccess: Actions.GET_I_TO_I_SUGGESTED_COST_SUCCESS,
  actionFail: Actions.GET_I_TO_I_SUGGESTED_COST_FAIL,
  callApiFn: newDLTransferAPI.getItoISuggestedCost,
})

const getEtoIInvoiceFile = getSimpleRequestHandler<
  typeof newDLTransferAPI.getEtoIInvoiceFile,
  ReturnType<typeof getEtoIInvoiceFileAction>
>({
  actionLoading: Actions.GET_E_TO_I_INVOICE_FILE_ASYNC,
  actionSuccess: Actions.GET_E_TO_I_INVOICE_FILE_SUCCESS,
  actionFail: Actions.GET_E_TO_I_INVOICE_FILE_FAIL,
  callApiFn: newDLTransferAPI.getEtoIInvoiceFile,
})

const getItoIInvoiceFile = getSimpleRequestHandler<
  typeof newDLTransferAPI.getItoIInvoiceFile,
  ReturnType<typeof getItoIInvoiceFileAction>
>({
  actionLoading: Actions.GET_I_TO_I_INVOICE_FILE_ASYNC,
  actionSuccess: Actions.GET_I_TO_I_INVOICE_FILE_SUCCESS,
  actionFail: Actions.GET_I_TO_I_INVOICE_FILE_FAIL,
  callApiFn: newDLTransferAPI.getItoIInvoiceFile,
})

const checkItoITransfersCount = getSimpleRequestHandler<
  typeof newDLTransferAPI.checkItoITransfersCount,
  ReturnType<typeof checkItoITransfersCountAction>
>({
  actionLoading: Actions.CHECK_ITOI_TRANSFERS_COUNT_ASYNC,
  actionSuccess: Actions.CHECK_ITOI_TRANSFERS_COUNT_SUCCESS,
  actionFail: Actions.CHECK_ITOI_TRANSFERS_COUNT_FAIL,
  callApiFn: newDLTransferAPI.checkItoITransfersCount,
})

export default [
  takeLatest(Actions.GET_E_TO_I_REQUESTS, getEtoIRequestsList),
  takeLatest(Actions.GET_E_TO_I_REQUEST_DETAILS, getEtoIRequestDetailsSaga),
  takeLatest(Actions.REJECT_E_TO_I_OFFER, rejectEToIOffer),
  takeLatest(Actions.PAY_FOR_E_TO_I_REQUEST, payForEToIRequestSaga),
  takeLatest(Actions.CANCEL_E_TO_I_CONTRACT, cancelEToIContract),
  takeLatest(Actions.ACCEPT_E_TO_I_OFFER, acceptEToIOfferSaga),
  takeLatest(Actions.ADD_E_TO_I_FINANCIAL_DOCUMENTS, addFinancialDocuments),
  takeLatest(Actions.E_TO_I_TRANSFER_WORKER, eToITransferWorker),
  takeLatest(Actions.E_TO_I_REPLACE_WORKER, eToIReplaceWorker),
  takeLatest(Actions.GET_I_TO_I_REQUESTS_LIST, getIToIRequestsList),
  takeLatest(Actions.GET_I_TO_I_LABORER_LIST, getIToILaborerList),
  takeLatest(Actions.CHECK_I_TO_I_TRANSFER_ELIGIBILITY, iToITransferEligibility),
  takeLatest(Actions.E_TO_I_CANCEL_REPLACEMENT, eToICancelReplacement),
  takeLatest(Actions.SUBMIT_I_TO_I_TRANSFER_REQUEST, submitIToITransferRequest),
  takeLatest(Actions.REJECT_I_TO_I_OFFER, rejectIToIOffer),
  takeLatest(Actions.ACCEPT_I_TO_I_OFFER, acceptIToIOffer),
  takeLatest(Actions.GET_I_TO_I_REQUEST_DETAILS, getIToIRequestDetails),
  takeLatest(Actions.GET_I_TO_I_LABORER_DETAILS, getIToILaborerDetails),
  takeLatest(Actions.ADD_I_TO_I_FINANCIAL_DOCUMENTS, addIToIFinancialDocuments),
  takeLatest(Actions.PAY_FOR_I_TO_I_REQUEST, payForIToIRequestSaga),
  takeLatest(Actions.GET_I_TO_I_BILL, getIToIBill),
  takeLatest(Actions.RESEND_E_TO_I_REQUEST, resendEToIRequest),
  takeLatest(Actions.GET_I_TO_I_SUGGESTED_COST, getItoISuggestedCost),
  takeLatest(Actions.GET_E_TO_I_INVOICE_FILE, getEtoIInvoiceFile),
  takeLatest(Actions.GET_I_TO_I_INVOICE_FILE, getItoIInvoiceFile),
  takeLatest(Actions.CHECK_ITOI_TRANSFERS_COUNT, checkItoITransfersCount),
]
