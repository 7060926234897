import axios, { AxiosPromise, AxiosResponse, AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import * as Sentry from '@sentry/browser'

import { SUCCESS, FAIL, RECAPTCHA_ERROR_CODE, INVALID_CREDENTIALS, EVISA_AUTH_HEADER } from 'common/constants'
import {
  EVISA_BASE_API_URL,
  ECONTRACTING_API_URL,
  PROS_BASE_API_URL,
  JIRA_MUSANED_API_URL,
  SURVEY_BASE_API_URL,
  WPS_MUSANED_API_URL,
  CONTRACT_AUTH_API_BASE_URL,
  NEW_DL_TRANSFER_API_BASE_URL,
  REFUND_BASE_URL,
  DL_IND_TO_IND_API_BASE,
  DL_LEASING_API_BASE,
  LABORER_INTERVIEW_API_URL,
  TAKAMOL_BASE_URL,
} from 'common/envConstants'
import { HttpStatusCode } from 'common/types/commonTypes'
import { Routes } from 'containers/Routes/routePaths'
import authManager from 'common/utils/auth/authManager'

const authHeader = {
  [EVISA_AUTH_HEADER]: {
    toString() {
      return authManager.getEvisaToken()
    },
  },
}

const jiraAuthHeader = {
  authorization: {
    toString() {
      return `Bearer ${authManager.getJiraToken()}`
    },
  },
}

const laborerPlatformAuthHeader = {
  authorization: {
    toString() {
      return `Bearer ${authManager.getContractAuthToken()}`
    },
  },
}

export const prosAPI = axios.create({
  baseURL: PROS_BASE_API_URL,
})

export const evisaAPI = axios.create({
  baseURL: EVISA_BASE_API_URL,
})

export const eContractingAPI = axios.create({
  baseURL: ECONTRACTING_API_URL,
})

export const surveyAPI = axios.create({
  baseURL: SURVEY_BASE_API_URL,
})

export const evisaAPIWithCredentials = axios.create({
  baseURL: EVISA_BASE_API_URL,
  headers: authHeader as unknown as AxiosRequestHeaders,
})

export const takamolAPIWithCredentials = axios.create({
  baseURL: TAKAMOL_BASE_URL,
  headers: authHeader as unknown as AxiosRequestHeaders,
})

export const newDLTransferAPIWithCredentials = axios.create({
  baseURL: NEW_DL_TRANSFER_API_BASE_URL,
  headers: authHeader as unknown as AxiosRequestHeaders,
})

export const indToIndAPIWithCredentials = axios.create({
  baseURL: DL_IND_TO_IND_API_BASE,
  headers: authHeader as unknown as AxiosRequestHeaders,
})

export const laborerTransferAPIWithCredentials = axios.create({
  baseURL: DL_IND_TO_IND_API_BASE,
  headers: laborerPlatformAuthHeader as unknown as AxiosRequestHeaders,
})

export const laborerAuthAPIWithCredentials = axios.create({
  baseURL: CONTRACT_AUTH_API_BASE_URL,
  headers: laborerPlatformAuthHeader as unknown as AxiosRequestHeaders,
})

export const laborerWpsAPIWithCredentials = axios.create({
  baseURL: WPS_MUSANED_API_URL,
  headers: laborerPlatformAuthHeader as unknown as AxiosRequestHeaders,
})

export const prosAPIWithCredentials = axios.create({
  baseURL: PROS_BASE_API_URL,
  headers: authHeader as unknown as AxiosRequestHeaders,
})

export const eContractingAPIWithCredentials = axios.create({
  baseURL: ECONTRACTING_API_URL,
  headers: authHeader as unknown as AxiosRequestHeaders,
})

export const refundAPIWithCredentials = axios.create({
  baseURL: REFUND_BASE_URL,
  headers: jiraAuthHeader as unknown as AxiosRequestHeaders,
})

export const jiraMusanedAPIWithCredentials = axios.create({
  baseURL: JIRA_MUSANED_API_URL,
  headers: jiraAuthHeader as unknown as AxiosRequestHeaders,
})

export const jiraMusanedAPI = axios.create({
  baseURL: JIRA_MUSANED_API_URL,
})

export const wpsAPIWithCredentials = axios.create({
  baseURL: WPS_MUSANED_API_URL,
  headers: authHeader as unknown as AxiosRequestHeaders,
})

export const contractAuthAPIWithCredentials = axios.create({
  baseURL: CONTRACT_AUTH_API_BASE_URL,
  headers: authHeader as unknown as AxiosRequestHeaders,
})

export const contractAuthAPI = axios.create({
  baseURL: CONTRACT_AUTH_API_BASE_URL,
})

export const prosLeasingAPIWithCredentials = axios.create({
  baseURL: DL_LEASING_API_BASE,
  headers: authHeader as unknown as AxiosRequestHeaders,
})

export const interviewAPIWithCredentials = axios.create({
  baseURL: LABORER_INTERVIEW_API_URL,
  headers: authHeader as unknown as AxiosRequestHeaders,
})

type CallAPIOptions = {
  silenceError?: boolean
  logoutOnAuthError?: boolean
}

export function callAPI<
  T extends (
    requestParams: any,
    config: AxiosRequestConfig<unknown>,
    callApiOptions?: CallAPIOptions,
    ...restArgs: any[]
  ) => AxiosPromise<RS>,
  RS = {},
  RF = {},
>(axiosCall: T) {
  return (
    requestParams: any,
    config: AxiosRequestConfig<unknown> = {},
    { silenceError = true, logoutOnAuthError = true }: CallAPIOptions = {},
    ...restArgs: any[]
  ) =>
    axiosCall(requestParams, config, ...restArgs)
      .then((resp: AxiosResponse<RS>) => {
        const newAuthToken = resp.headers[EVISA_AUTH_HEADER]
        if (newAuthToken) {
          authManager.setAuthData({
            evisaToken: newAuthToken,
          })
        }
        return {
          response: resp.data,
          headers: resp.headers,
          status: SUCCESS,
        }
      })
      .catch((err: AxiosError<RF>) => {
        const isTokeExpired =
          err.response?.status === HttpStatusCode.UNAUTHORIZED &&
          !(
            (
              err.response?.data as any
            ) /* here response could be anything but we are checking here for a specific response */?.error?.code ===
              RECAPTCHA_ERROR_CODE ||
            (
              err.response?.data as any
            ) /* here response could be anything but we are checking here for a specific response */?.error
              ?.message === INVALID_CREDENTIALS
          )
        axios.isCancel(err) ||
          Sentry.withScope((scope) => {
            scope.setUser({ id: authManager.getUserNameFromEvisaToken() })
            scope.setLevel(Sentry.Severity.Debug)
            scope.setExtra('expiredToken', isTokeExpired)
            scope.setTransactionName(err.config.url || 'ResponseException')
            scope.setExtra('response', err.response?.data)
            Sentry.captureException(err)
          })
        if (logoutOnAuthError && isTokeExpired && window.location.pathname !== Routes.LOGIN) {
          authManager.logOut(true)
        }
        const errorObject = {
          response: err?.response?.data,
          headers: err?.response?.headers,
          status: FAIL,
        }
        if (!silenceError) {
          // in case we want to handle error manualy or apply saga effects related to errror handling
          // we throw this object istead of returning it
          // eslint-disable-next-line no-throw-literal, @typescript-eslint/no-throw-literal
          throw errorObject
        }
        return errorObject
      })
}

export type CallApiWrapper = ReturnType<typeof callAPI>
export type CallApiFnResponse = ReturnType<CallApiWrapper>
