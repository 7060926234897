import i18n from 'i18n'
import { Lang } from 'common/types/commonTypes'

export enum TranslationNamespace {
  labibaChatBot = 'labibaChatBot',
  common = 'common',
  uiComponents = 'uiComponents',
  footer = 'footer',
  validation = 'validation',
  secondaryHeader = 'secondaryHeader',
  servicesCatalogue = 'servicesCatalogue',
  servicesCatalogueList = 'servicesCatalogueList',
  cancelDisclaimerPage = 'cancelDisclaimerPage',
  contractComplaintPage = 'contractComplaintPage',
  contractDetailsPage = 'contractDetailsPage',
  cancellationRequestPage = 'cancellationRequestPage',
  contractRequestPage = 'contractRequestPage',
  contractsPage = 'contractsPage',
  contractsList = 'contractsList',
  contractAuthReqestsList = 'contractAuthReqestsList',
  cVSelectPage = 'cVSelectPage',
  contractPaymentPage = 'contractPaymentPage',
  nationalitiesTermsPage = 'nationalitiesTermsPage',
  maarofaApproval = 'maarofaApproval',
  maarofaOfficeSelect = 'maarofaOfficeSelect',
  maarofaOfficeType = 'maarofaOfficeType',
  maarofaSelectVisa = 'maarofaSelectVisa',
  maarofaWorkerInfo = 'maarofaWorkerInfo',
  insuranceModal = 'insuranceModal',
  maarofaWorkerTypeSelect = 'maarofaWorkerTypeSelect',
  visaPreview = 'visaPreview',
  visasListPage = 'visasListPage',
  approvalForAlternativeVisaPage = 'approvalForAlternativeVisaPage',
  replacementConfirmationPage = 'replacementConfirmationPage',
  visasToReplacePage = 'visasToReplacePage',
  dashboardWarningMessage = 'dashboardWarningMessage',
  approvalForDisabilityVisaPage = 'approvalForDisabilityVisaPage',
  disabilityVisaConfirmation = 'disabilityVisaConfirmation',
  approvalForRegularVisaPage = 'approvalForRegularVisaPage',
  regularVisaConfirmation = 'regularVisaConfirmation',
  supportTicketsList = 'supportTicketsList',
  supportTicketsForm = 'supportTicketsForm',
  supportTicketsDetails = 'supportTicketsDetails',
  maarofaTerms = 'maarofaTerms',
  visaPaymentPage = 'visaPaymentPage',
  helpPage = 'helpPage',
  noticePaymentPage = 'noticePaymentPage',
  marketPage = 'marketPage',
  marketplaceCard = 'marketplaceCard',
  payment = 'payment',
  processBlock = 'processBlock',
  maarofaProcessBlock = 'maarofaProcessBlock',
  requestOffer = 'requestOffer',
  eligibilityDocuments = 'eligibilityDocuments',
  trackingStep = 'trackingStep',
  appLayout = 'appLayout',
  attachmentsPage = 'attachmentsPage',
  authPage = 'authPage',
  complaintTicketsPage = 'complaintTicketsPage',
  complaintTicketDetailsPage = 'complaintTicketDetailsPage',
  confirmationPage = 'confirmationPage',
  dashboardPage = 'dashboardPage',
  unifyJourneyLayout = 'unifyJourneyLayout',
  privacyPolicyPage = 'privacyPolicyPage',
  commonTerms = 'commonTerms',
  agreementCheckbox = 'agreementCheckbox',
  attachmentsCheckbox = 'attachmentsCheckbox',
  servicesPage = 'servicesPage',
  homePage = 'homePage',
  homePageStatistic = 'homePageStatistic',
  preferencesProcedure = 'preferencesProcedure',
  refundFormSADAD = 'refundFormSADAD',
  refundFormPayFort = 'refundFormPayFort',
  refundList = 'refundList',
  visaList = 'visaList',
  profileNavigation = 'profileNavigation',
  profileDetails = 'profileDetails',
  changePassword = 'changePassword',
  noticesListPage = 'noticesListPage',
  createNoticeForm = 'createNoticeForm',
  createNoticeDisclaimer = 'createNoticeDisclaimer',
  domesticTransferDisclaimer = 'domesticTransferDisclaimer',
  domesticTransferRequestFinalize = 'domesticTransferRequestFinalize',
  domesticTransferRequestForm = 'domesticTransferRequestForm',
  domesticTransferRequestTable = 'domesticTransferRequestTable',
  selectedValuesTable = 'selectedValuesTable',
  serviceTransferRequestsList = 'serviceTransferRequestsList',
  payfortResponseMessage = 'payfortResponseMessage',
  approvalForFarmerVisaPage = 'approvalForFarmerVisaPage',
  farmerVisaConfirmation = 'farmerVisaConfirmation',
  farmerVisaDescription = 'farmerVisaDescription',
  farmerEndorsementSelection = 'farmerEndorsementSelection',
  salaryWarning = 'salaryWarning',
  surveyPage = 'surveyPage',
  faqReg = 'faqReg',
  keepAliveModal = 'keepAliveModal',
  visaInquiryPage = 'visaInquiryPage',
  farmerVisaPaymentPage = 'farmerVisaPaymentPage',
  cookiesDisclosureBar = 'cookiesDisclosureBar',
  maarofaServiceBar = 'maarofaServiceBar',
  payfortBanksSupportModal = 'payfortBanksSupportModal',
  errorPage = 'errorPage',
  maarofaServicePage = 'maarofaServicePage',
  billPreview = 'billPreview',
  messageContent = 'messageContent',
  modalMessage = 'modalMessage',
  emptyListMessage = 'emptyListMessage',
  farmerEstablishmentPage = 'farmerEstablishmentPage',
  installmentServiceTerms = 'installmentServiceTerms',
  farmerEstablishmentConfirmation = 'farmerEstablishmentConfirmation',
  extensionPage = 'extensionPage',
  donationMessage = 'donationMessage',
  farmerTransferRequestForm = 'farmerTransferRequestForm',
  farmerTransferDisclaimer = 'farmerTransferDisclaimer',
  farmerTransferRequestSummary = 'farmerTransferRequestSummary',
  farmerTransferRequestPayment = 'farmerTransferRequestPayment',
  eToIRequests = 'eToIRequests',
  eToIRequestDetails = 'eToIRequestDetails',
  eToIRequestRejectionModal = 'eToIRequestRejectionModal',
  eToITransferActions = 'eToITransferActions',
  eToIAcceptancePayment = 'eToIAcceptancePayment',
  eToIAcceptanceContract = 'eToIAcceptanceContract',
  eToIAcceptanceAttachments = 'eToIAcceptanceAttachments',
  cancelEToIContractModal = 'cancelEToIContractModal',
  farmerTransferList = 'farmerTransferList',
  multiLevelWataniSurvey = 'multiLevelWataniSurvey',
  serviceIsDownModal = 'serviceIsDownModal',
  proDetails = 'proDetails',
  wageProtectionListPage = 'wageProtectionListPage',
  wageProtectionLaborersList = 'wageProtectionLaborersList',
  WPLaborDetailsPage = 'WPLaborDetailsPage',
  wpsTransactionDetails = 'wpsTransactionDetails',
  wpsCommitment = 'wpsCommitment',
  newBillPreview = 'newBillPreview',
  contractBillSelectModal = 'contractBillSelectModal',
  contractAuthApproval = 'contractAuthApproval',
  authContract = 'authContract',
  authContractDetails = 'authContractDetails',
  contractAuthLaborersList = 'contractAuthLaborersList',
  contractAuthRequestDetails = 'contractAuthRequestDetails',
  contractAuthLaborerDetails = 'contractAuthLaborerDetails',
  contractInsuranceSelection = 'contractInsuranceSelection',
  previewAuthContract = 'previewAuthContract',
  authContractCancellation = 'authContractCancellation',
  contractSelectModal = 'contractSelectModal',
  visaWarningBanner = 'visaWarningBanner',
  insuranceDetails = 'insuranceDetails',
  establishmentRequestInformation = 'establishmentRequestInformation',
  salaryAdjustment = 'salaryAdjustment',
  phoneChange = 'phoneChange',
  phoneVerification = 'phoneVerification',
  verifyField = 'verifyField',
  mobileVerifyModal = 'mobileVerifyModal',
  iToERequestsList = 'iToERequestsList',
  iToERequestDetails = 'iToERequestDetails',
  iToESecondaryHeader = 'iToESecondaryHeader',
  eToIContract = 'eToIContract',
  eToIAppendix = 'eToIAppendix',
  contractHeader = 'contractHeader',
  eToITransferWorkerModal = 'eToITransferWorkerModal',
  eToIReplaceWorkerModal = 'eToIReplaceWorkerModal',
  eToIPaymentModal = 'eToIPaymentModal',
  eToITransferPage = 'eToITransferPage',
  eToICancelPage = 'eToICancelPage',
  eToIReplacePage = 'eToIReplacePage',
  outdatedIbanModal = 'outdatedIbanModal',
  approvalForCancellationContractPage = 'approvalForCancellationContractPage',
  refundRequest = 'refundRequest',
  iToIRequestsList = 'iToIRequestsList',
  iToILaborerListPage = 'iToILaborerListPage',
  iToIRequestForm = 'iToIRequestForm',
  eToICancelReplacement = 'eToICancelReplacement',
  iToIRequestDetails = 'iToIRequestDetails',
  laborerServiceTransferPage = 'laborerServiceTransferPage',
  transferRequestDetailsPage = 'transferRequestDetailsPage',
  transferRequestRelativeInfoPage = 'transferRequestRelativeInfoPage',
  rejectTransferRequestModal = 'rejectTransferRequestModal',
  laborerTransferContract = 'laborerTransferContract',
  employmentContract = 'employmentContract',
  laborerSupport = 'laborerSupport',
  laborerSupportDetailsPage = 'laborerSupportDetailsPage',
  laborerSupportTypePage = 'laborerSupportTypePage',
  laborerComplaintDetailsPage = 'laborerComplaintDetailsPage',
  laborerSupportConfirmMobile = 'laborerSupportConfirmMobile',
  supportSuccessModal = 'supportSuccessModal',
  survey = 'survey',
  iToIRequestRejectionModal = 'iToIRequestRejectionModal',
  transferContract = 'transferContract',
  employerContract = 'employerContract',
  iToIPaymentPage = 'iToIPaymentPage',
  iToIAcceptanceAttachments = 'iToIAcceptanceAttachments',
  recruitmentGenderSelect = 'recruitmentGenderSelect',
  recruitmentNationalitySelect = 'recruitmentNationalitySelect',
  recruitmentOccupationSelect = 'recruitmentOccupationSelect',
  recruitmentLaborerInfoSelect = 'recruitmentLaborerInfoSelect',
  recruitmentPreferences = 'recruitmentPreferences',
  recruitmentMarket = 'recruitmentMarket',
  recruitmentOffices = 'recruitmentOffices',
  recruitmentTypeSelect = 'recruitmentTypeSelect',
  recruitmentPreselected = 'recruitmentPreselected',
  recruitmentAttachmentsPage = 'recruitmentAttachmentsPage',
  recruitmentPersonalData = 'recruitmentPersonalData',
  recruitmentSuccessPage = 'recruitmentSuccessPage',
  support = 'support',
  supportPageForm = 'supportPageForm',
  contactUs = 'contactUs',
  iToITransferActions = 'iToITransferActions',
  approvedAppendix = 'approvedAppendix',
  authServiceBanner = 'authServiceBanner',
  supportPageSelectContract = 'supportPageSelectContract',
  supportPageSelectVisa = 'supportPageSelectVisa',
  supportPageSelectTransfer = 'supportPageSelectTransfer',
  complaintInquiryPage = 'complaintInquiryPage',
  contractAuthInstruction = 'contractAuthInstruction',
  runaways = 'runaways',
  iToIAcceptanceApproval = 'iToIAcceptanceApproval',
  regularVisaInstruction = 'regularVisaInstruction',
  refundDisclaimerPage = 'refundDisclaimerPage',
  cities = 'cities',
  runawaysListActions = 'runawaysListActions',
  editAuthContract = 'EditAuthContract',
  authContractAppendix = 'authContractAppendix',
  previewAuthAppendix = 'previewAuthAppendix',
  sendOTPModal = 'sendOTPModal',
  financialExemption = 'financialExemption',
  laborerServiceTransferActions = 'laborerServiceTransferActions',
  iToIContract = 'iToIContract',
  authContractText = 'authContractText',
  directContractApproval = 'directContractApproval',
  visaDetailsPage = 'visaDetailsPage',
  farmerVisaDetailsPage = 'farmerVisaDetailsPage',
  faqContent = 'faqContent',
  nationalAddresses = 'nationalAddresses',
  editMobileNumber = 'editMobileNumber',
  newCVSelectPage = 'newCVSelectPage',
  contractSuccessPage = 'contractSuccessPage',
  newContractRequestPage = 'newContractRequestPage',
  newContractInsuranceSelection = 'newContractInsuranceSelection',
  newNationalitiesTermsPage = 'newNationalitiesTermsPage',
  newContractDetailsPage = 'newContractDetailsPage',
  newContractPaymentPage = 'newContractPaymentPage',
  paymentNew = 'paymentNew',
  progressTimeline = 'progressTimeline',
  contractCancellationPage = 'contractCancellationPage',
  cancellationRequestPageNew = 'cancellationRequestPageNew',
  recruitmentNationalitiesTerms = 'recruitmentNationalitiesTerms',
  contractSelectModalNew = 'contractSelectModalNew',
  newDetailsHeader = 'newDetailsHeader',
  iToIPaymentModal = 'iToIPaymentModal',
  officeDetails = 'officeDetails',
  videoDisclaimer = 'videoDisclaimer',
  refundRequestNew = 'refundRequestNew',
  laborerAuthRequest = 'laborerAuthRequest',
  laborerAuthRequestDetails = 'laborerAuthRequestDetails',
  laborerAuthAppendixDetails = 'laborerAuthAppendixDetails',
  rejectLaborerAuthRequestModal = 'rejectLaborerAuthRequestModal',
  laborerAuthEmploymentContract = 'laborerAuthEmploymentContract',
  authRequestActions = 'authRequestActions',
  newContractChooseInsurance = 'newContractChooseInsurance',
  newContractTameeniDisclaimerPage = 'newContractTameeniDisclaimerPage',
  authContractInfo = 'authContractInfo',
  searchSuggestion = 'searchSuggestion',
  reviewAuthContract = 'reviewAuthContract',
  insuranceInfo = 'insuranceInfo',

  vipDashboard = 'vipDashboard',
  welcomeBar = 'welcomeBar',
  vipDetailsBanner = 'vipDetailsBanner',
  profileCompletionBar = 'profileCompletionBar',
  agent = 'agent',
  relationshipManager = 'relationshipManager',
  vipProfile = 'vipProfile',
  vipLayout = 'vipLayout',
  vipRecruitment = 'vipRecruitment',
  vipCardRating = 'vipCardRating',
  vipPayment = 'vipPayment',
  vipRecruitmentSelectType = 'vipRecruitmentSelectType',
  vipRecruitmentLaborerInfo = 'vipRecruitmentLaborerInfo',
  vipRecruitmentMarketplace = 'vipRecruitmentMarketplace',
  vipMarketplaceCard = 'vipMarketplaceCard',
  vipOfficeDetails = 'vipOfficeDetails',
  vipRecruitmentNationalitiesTerms = 'vipRecruitmentNationalitiesTerms',
  vipConfirmationPage = 'vipConfirmationPage',
  vipRequestDetails = 'vipRequestDetails',
  vipLaborerDetails = 'vipLaborerDetails',
  vipAdditionalServices = 'vipAdditionalServices',
  laborerCVs = 'laborerCVs',
  vipContractPaymentPage = 'vipContractPaymentPage',
  recruitment = 'recruitment',
  DelayedContracts = 'DelayedContracts',
  vipVisas = 'vipVisas',
  vipRequestActions = 'vipRequestActions',
  performanceRate = 'performanceRate',
  pendingActions = 'pendingActions',
  unsubscribeLaborerPage = 'unsubscribeLaborerPage',
  vipLatestRequests = 'vipLatestRequests',
  relationshipManagerBar = 'relationshipManagerBar',
  vipRequestOffice = 'vipRequestOffice',
  vipTerms = 'vipTerms',
  reportLaborerModal = 'reportLaborerModal',
  languageModal = 'languageModal',
  transferRequestsType = 'transferRequestsType',
  listLaborerEligibility = 'listLaborerEligibility',
  listLaborerForm = 'listLaborerForm',
  financialEligibilityForRegularVisa = 'financialEligibilityForRegularVisa',
  laborerListingRequests = 'laborerListingRequests',
  laborerListingRequestDetails = 'laborerListingRequestDetails',
  disablePage = 'disablePage',
  hiringLaborer = 'hiringLaborer',
  listingLaborerInfo = 'listingLaborerInfo',
  listingMarketplace = 'listingMarketplace',
  listingLaborerDetails = 'listingLaborerDetails',
  listingNationalityTerms = 'listingNationalityTerms',
  listingTransferDetails = 'listingTransferDetails',
  directTransferList = 'directTransferList',
  directTransferDetails = 'directTransferDetails',
  directTransferOffer = 'directTransferOffer',
  interviewServiceDetails = 'interviewServiceDetails',
  interviewLaborerInformation = 'interviewLaborerInformation',
  interviewSelectionOffice = 'interviewSelectionOffice',
  interviewServicePayment = 'interviewServicePayment',
  interviewRequest = 'interviewRequest',
  interviewOfficeCard = 'interviewOfficeCard',
  interviewsList = 'interviewsList',
  interviewDetails = 'interviewDetails',
  offlineSalaryPayment = 'offlineSalaryPayment',
  salaryPayment = 'salaryPayment',
  kabiServiceBar = 'kabiServiceBar',
  interviewResult = 'interviewResult',
  interviewBanner = 'interviewBanner',
  newDashboardBanner = 'newDashboardBanner',
  vipSpecialServicesList = 'vipSpecialServicesList',
}

export const addTranslationNamespace = (
  namespace: TranslationNamespace,
  langEn: {},
  langAr: {},
  langUr?: {},
  langAm?: {},
  langFil?: {},
  langHi?: {},
) => {
  i18n.addResourceBundle(Lang.en, namespace, langEn, true)
  i18n.addResourceBundle(Lang.ar, namespace, langAr, true)
  i18n.addResourceBundle(Lang.ur, namespace, langUr, true)
  i18n.addResourceBundle(Lang.am, namespace, langAm, true)
  i18n.addResourceBundle(Lang.fil, namespace, langFil, true)
  i18n.addResourceBundle(Lang.hi, namespace, langHi, true)
}
