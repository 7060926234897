import { AxiosRequestConfig } from 'axios'

import { GeneralFailResponse, SpecialServiceStatuses, SpecialServiceTypes } from 'common/types/commonTypes'

import { callAPI, takamolAPIWithCredentials } from '../axios'

export type GetSpecialServicesListParams = {
  page?: number
  perPage?: number
  service?: SpecialServiceTypes
  status?: string
}

export type SpecialServiceFromBE = {
  display_id: string
  name: string
  id_number: string
  service: SpecialServiceTypes
  created_at: string
  status: SpecialServiceStatuses
  relationship_manager_name: string
}

export type GetSpecialServicesListResponse = {
  data: Array<SpecialServiceFromBE>
  current_page: number
  last_page: number
}

const getSpecialServicesList = (
  { service, status, page = 0, perPage = 10 }: GetSpecialServicesListParams,
  config: AxiosRequestConfig,
) =>
  takamolAPIWithCredentials.get<GetSpecialServicesListResponse>('/api/individuals/special-services', {
    params: {
      service,
      status,
      page: page + 1,
      per_page: perPage,
    },
    ...config,
  })

export default {
  getSpecialServicesList: callAPI<typeof getSpecialServicesList, GetSpecialServicesListResponse, GeneralFailResponse>(
    getSpecialServicesList,
  ),
}
