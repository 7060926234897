import { combineReducers } from 'redux'

import auth from './reducers/auth'
import authFlow from './reducers/authFlow'
import allMarketOffers from './reducers/allMarketOffers'
import marketOffers from './reducers/marketOffers'
import marketSelectedOffers from './reducers/marketSelectedOffers'
import marketFilter from './reducers/marketFilter'
import arrivalCities from './reducers/arrivalCities'
import nationalities from './reducers/nationalities'
import occupations from './reducers/occupations'
import laborOffices from './reducers/laborOffices'
import priceRange from './reducers/priceRange'
import maarofaServiceTemplate from './reducers/maarofaServiceTemplate'
import prosStatistic from './reducers/prosStatistic'
import recruitmentDurationRange from './reducers/recruitmentDurationRange'
import userVisaEligibility from './reducers/userVisaEligibility'
import isUserHasVisas from './reducers/isUserHasVisas'
import uploadVisasFilesStatus from './reducers/uploadVisasFilesStatus'
import visaWithContractRequestStatus from './reducers/visaWithContractRequestStatus'
import lang from './reducers/language'
import preferencesForm from './reducers/preferencesForm'
import preselectedForm from './reducers/preselectedForm'
import contractRequestInfo from './reducers/contractRequestInfo'
import cvByPreferences from './reducers/cvByPreferences'
import visaInfo from './reducers/visaInfo'
import paymentInfo from './reducers/paymentInfo'
import payForEContractStatus from './reducers/payForEContractStatus'
import acceptOfferRequestStatus from './reducers/acceptOfferRequestStatus'
import accessibilityTools from './reducers/accessibilityTools'
import visasList from './reducers/visasList'
import visaIssueRequestsList from './reducers/visaIssueRequestsList'
import contractRequestsList from './reducers/contractRequestsList'
import availableVisas from './reducers/availableVisas'
import maarofaRequest from './reducers/maarofaRequest'
import maarofaRequestStatus from './reducers/maarofaRequestStatus'
import maarofaOffices from './reducers/maarofaOffices'
import visaIssuePlaces from './reducers/visaIssuePlaces'
import Actions from './actions'
import workers from './reducers/workers'
import notices from './reducers/notices'
import banksList from './reducers/banksList'
import noticeAvailability from './reducers/noticeAvailability'
import notice from './reducers/notice'
import payNotice from './reducers/payNotice'
import ticketsList from './reducers/ticketsList'
import ticket from './reducers/ticket'
import cancelContractStatus from './reducers/cancelContractStatus'
import rejectOfferStatus from './reducers/rejectOfferStatus'
import officeInfo from './reducers/officeInfo'
import locationDetails from './reducers/locationDetails'
import changePasswordStatus from './reducers/changePasswordStatus'
import issueNewVisaRequestStatus from './reducers/issueNewVisaRequestStatus'
import respondToComplaintSolutionStatus from './reducers/respondToComplaintSolutionStatus'
import respondToOfficeComplaintStatus from './reducers/respondToOfficeComplaintStatus'
import eToILegacyRequests from './reducers/eToILegacyRequests'
import domesticTransferCreating from './reducers/domesticTransferCreating'
import laborerInfo from './reducers/laborerInfo'
import alternativeVisaFlow from './reducers/alternativeVisaFlow'
import regularVisaFlow from './reducers/regularVisaFlow'
import eligibleWorkers from './reducers/eligibleWorkers'
import replaceableVisa from './reducers/replaceableVisa'
import replaceVisasStatus from './reducers/replaceVisasStatus'
import skills from './reducers/skills'
import rejectionReasonsList from './reducers/rejectionReasonsList'
import visaCancellationStatus from './reducers/visaCancellationStatus'
import supportTickets from './reducers/supportTickets'
import createComplaintStatus from './reducers/createComplaintStatus'
import cancelRequestStatus from './reducers/cancelRequestStatus'
import disabilityVisaFlow from './reducers/disabilityVisaFlow'
import disabilityVIsaEligibility from './reducers/disabilityVIsaEligibility'
import issueNewDisabilityVisaStatus from './reducers/issueNewDisabilityVisaStatus'
import alternativeVisaEligibility from './reducers/alternativeVisaEligibility'
import visaIssueRequestInfo from './reducers/visaIssueRequestInfo'
import vatInfo from './reducers/vatInfo'
import complaintsRating from './reducers/complaintsRating'
import complaintsRatingV2 from './reducers/complaintsRatingV2'
import refundSADAD from './reducers/refundSADAD'
import refundPayFort from './reducers/refundPayFort'
import refundableBills from './reducers/refundableBills'
import outdatedIBAN from './reducers/outdatedIBAN'
import domesticTransferForm from './reducers/domesticTransferForm'
import newNoticeFlow from './reducers/newNoticeFlow'
import createNoticeStatus from './reducers/createNoticeStatus'
import downloadHistory from './reducers/downloadHistory'
import onlinePaymentVisa from './reducers/onlinePaymentVisa'
import farmerVisaEligibility from './reducers/farmerVisaEligibility'
import farmerVisaFlow from './reducers/farmerVisaFlow'
import issueNewFarmerVisaStatus from './reducers/issueNewFarmerVisaStatus'
import labors from './reducers/labors'
import updateLaborsStatus from './reducers/updateLaborsStatus'
import visaInquiryStatus from './reducers/visaInquiryStatus'
import farmerVisaRequests from './reducers/farmerVisaRequests'
import infoAboutFarmerRequest from './reducers/infoAboutFarmerRequest'
import payForFarmerVisaStatus from './reducers/payForFarmerVisaStatus'
import cookiesDisclaimerBar from './reducers/cookiesDisclaimerBar'
import maarofaBar from './reducers/maarofaBar'
import farmerVisaCancelationStatus from './reducers/farmerVisaCancelationStatus'
import banksInfo from './reducers/banksInfo'
import agriculturalRegisterationFile from './reducers/agriculturalRegisterationFile'
import agriculturalSubmission from './reducers/agriculturalSubmission'
import extendContractStatus from './reducers/extendContractStatus'
import farmerTransferEligibility from './reducers/farmerTransferEligibility'
import farmerTransferability from './reducers/farmerTransferability'
import submitWataniSurveyStatus from './reducers/submitWataniSurveyStatus'
import farmerTransfer from './reducers/farmerTransfer'
import cancelFTRequestStatus from './reducers/cancelFTRequestStatus'
import acceptFTRequestStatus from './reducers/acceptFTRequestStatus'
import rejectFTRequestStatus from './reducers/rejectFTRequestStatus'
import issueNewFarmerTransferStatus from './reducers/issueNewFarmerTransferStatus'
import payForFarmerTransferStatus from './reducers/payForFarmerTransferStatus'
import proServiceDetails from './reducers/proServiceDetails'
import saveUserProfileStatus from './reducers/saveUserProfileStatus'
import addComplaintMissingAttachmentStatus from './reducers/addComplaintMissingAttachmentStatus'
import wpsLaborersList from './reducers/wpsLaborersList'
import wpsCurrentLaborer from './reducers/wpsCurrentLaborer'
import wpsLaborerTransactions from './reducers/wpsLaborerTransactions'
import wpsCurrentTransaction from './reducers/wpsCurrentTransaction'
import wpsCommitmentsStatistic from './reducers/wpsCommitmentsStatistic'
import createContractAuthRequest from './reducers/createContractAuthRequest'
import contractAuthEmployerLaborers from './reducers/contractAuthEmployerLaborers'
import contractAuthRequestsList from './reducers/contractAuthRequestsList'
import occupationDescriptions from './reducers/occupationDescriptions'
import laborerPhoneForContractAuthStatus from './reducers/laborerPhoneForContractAuthStatus'
import createContractAuthRequestStatus from './reducers/createContractAuthRequestStatus'
import contractAuthDetailedRequest from './reducers/contractAuthDetailedRequest'
import cancelContractAuthRequestStatus from './reducers/cancelContractAuthRequestStatus'
import requestIdForContractIdStatus from './reducers/requestIdForContractIdStatus'
import eTawtheeqContractStatus from './reducers/eTawtheeqContractStatus'
import najmRedirectionUrlStatus from './reducers/najmRedirectionUrlStatus'
import mobileVerify from './reducers/mobileVerify'
import mobileVerifyStatus from './reducers/mobileVerifyStatus'
import checkCaptchaStatus from './reducers/checkCaptchaStatus'
import iToERequests from './reducers/iToERequests'
import eToIRequests from './reducers/eToIRequests'
import eToIRequest from './reducers/eToIRequest'
import iToERequestDetails from './reducers/iToERequestDetails'
import iToERequestActions from './reducers/iToERequestActions'
import rejectEToIOfferStatus from './reducers/rejectEToIOfferStatus'
import acceptEToIOfferStatus from './reducers/acceptEToIOfferStatus'
import payForEToIRequestStatus from './reducers/payForEToIRequestStatus'
import cancelEToIContractStatus from './reducers/cancelEToIContractStatus'
import eToIFinancialDocuments from './reducers/eToIFinancialDocuments'
import eToITransferWorkerStatus from './reducers/eToITransferWorkerStatus'
import eToIReplaceWorkerStatus from './reducers/eToIReplaceWorkerStatus'
import rejectContractStatus from './reducers/rejectContractStatus'
import acceptContractStatus from './reducers/acceptContractStatus'
import refundDetails from './reducers/refundDetails'
import contractInfo from './reducers/contractInfo'
import laborerLoginStatus from './reducers/laborerLoginStatus'
import laborerOtpVerifyStatus from './reducers/laborerOtpVerifyStatus'
import iToIRequestsList from './reducers/iToIRequestsList'
import iToILaborerList from './reducers/iToILaborerList'
import iToITransferEligibility from './reducers/iToITransferEligibility'
import eToICancelReplacementStatus from './reducers/eToICancelReplacementStatus'
import iToITransferRequestStatus from './reducers/iToITransferRequestStatus'
import laborerTransfersList from './reducers/laborerTransfersList'
import laborerTransferDetails from './reducers/laborerTransferDetails'
import acceptLaborerTransferRequestStatus from './reducers/acceptLaborerTransferRequestStatus'
import rejectLaborerTransferRequestStatus from './reducers/rejectLaborerTransferRequestStatus'
import rejectIToIOfferStatus from './reducers/rejectIToIOfferStatus'
import acceptIToIOfferStatus from './reducers/acceptIToIOfferStatus'
import iToIRequestDetails from './reducers/iToIRequestDetails'
import iToILaborerDetails from './reducers/iToILaborerDetails'
import iToIFinancialDocuments from './reducers/iToIFinancialDocuments'
import laborerRefreshToken from './reducers/laborerRefreshToken'
import removeFromPilotList from './reducers/removeFromPilotList'
import payForIToIRequestStatus from './reducers/payForIToIRequestStatus'
import supportTicketsGuest from './reducers/supportTicketsGuest'
import createRefundTicketStatus from './reducers/createRefundTicketStatus'
import iToIBill from './reducers/iToIBill'
import authBanner from './reducers/authBanner'
import supportTicket from './reducers/supportTicket'
import lastFourVisas from './reducers/lastFourVisas'
import complaintInquiryStatus from './reducers/complaintInquiryStatus'
import resendEToIRequestStatus from './reducers/resendEToIRequestStatus'
import runawaysList from './reducers/runawaysList'
import reportRunawayLaborerStatus from './reducers/reportRunawayLaborerStatus'
import cancelReportRunawayLaborerStatus from './reducers/cancelReportRunawayLaborerStatus'
import laborerIToIRelativeInfo from './reducers/laborerIToIRelativeInfo'
import iToISuggestedCost from './reducers/iToISuggestedCost'
import sendAbsherUpdateMobileStatus from './reducers/sendAbsherUpdateMobileStatus'
import verifyAbsherUpdateMobileStatus from './reducers/verifyAbsherUpdateMobileStatus'
import sendOtpUpdateMobileStatus from './reducers/sendOtpUpdateMobileStatus'
import verifyOtpUpdateMobileStatus from './reducers/verifyOtpUpdateMobileStatus'
import sendLaborerRelativeInfo from './reducers/sendLaborerRelativeInfo'
import contractAuthAppendix from './reducers/contractAuthAppendix'
import createContractAuthAppendixStatus from './reducers/createContractAuthAppendixStatus'
import getEligibleLaborers from './reducers/getEligibleLaborers'
import checkFeesExemption from './reducers/checkFeesExemption'
import uploadDisabilityProofStatus from './reducers/uploadDisabilityProofStatus'
import submitFeesExemptionStatus from './reducers/submitFeesExemptionStatus'
import updateNationalAddress from './reducers/updateNationalAddress'
import setNewPrimaryAddress from './reducers/setNewPrimaryAddress'
import updateLaborerMobileNumberStatus from './reducers/updateLaborerMobileNumberStatus'
import recruitmentFilter from './reducers/recruitmentFilter'
import visaInvoiceFile from './reducers/visaInvoiceFile'
import eToIInvoiceFile from './reducers/eToIInvoiceFile'
import iToIInvoiceFile from './reducers/iToIInvoiceFile'
import visaPdfUrl from './reducers/visaPdfUrl'
import cancelContractAuthAppendixStatus from './reducers/cancelContractAuthAppendixStatus'
import proServiceAddons from './reducers/proServiceAddons'
import proServiceAddonsAll from './reducers/proServiceAddonsAll'
import contractInvoiceFile from './reducers/contractInvoiceFile'
import cvReportStatus from './reducers/cvReportStatus'
import showComplaintsSurvey from './reducers/showComplaintsSurvey'
import surveyQuestions from './reducers/surveyQuestions'
import proPerformance from './reducers/proPerformance'
import proPerformanceOverall from './reducers/proPerformanceOverall'
import proPerformanceFilter from './reducers/proPerformanceFilter'
import createAgent from './reducers/createAgent'
import acceptanceRates from './reducers/acceptanceRates'
import verifyAgent from './reducers/verifyAgent'
import loginNafath from './reducers/loginNafath'
import checkNafath from './reducers/checkNafath'
import removeAgentStatus from './reducers/removeAgentStatus'
import laborerAuthRequestList from './reducers/laborerAuthRequestList'
import laborerAuthRequestDetails from './reducers/laborerAuthRequestDetails'
import laborerAuthContractText from './reducers/laborerAuthContractText'
import acceptLaborerAuthRequestStatus from './reducers/acceptLaborerAuthRequestStatus'
import rejectLaborerAuthRequestStatus from './reducers/rejectLaborerAuthRequestStatus'
import authRejectionReasonList from './reducers/authRejectionReasonList'
import laborerAuthAppendixList from './reducers/laborerAuthAppendixList'
import laborerAuthAppendixDetails from './reducers/laborerAuthAppendixDetails'
import rejectLaborerAuthAppendix from './reducers/rejectLaborerAuthAppendix'
import approveLaborerAuthAppendix from './reducers/approveLaborerAuthAppendix'
import authContractDraft from './reducers/authContractDraft'
import vipRecruitmentFilter from './reducers/vipRecruitmentFilter'
import vipPreselectedInfo from './reducers/vipPreselectedInfo'
import simpleAuthContractInfo from './reducers/simpleAuthContractInfo'
import authLaborerContractPdf from './reducers/authLaborerContractPdf'
import authContractPdf from './reducers/authContractPdf'
import authContract from './reducers/authContract'
import vipContractRequest from './reducers/vipContractRequest'
import authPendingAppendix from './reducers/authPendingAppendix'
import authPendingLaborerAppendix from './reducers/authPendingLaborerAppendix'
import tameeniRedirectionUrlStatus from './reducers/tameeniRedirectionUrlStatus'
import delayedContractCounts from './reducers/delayedContractCounts'
import vipVisaList from './reducers/vipVisaList'
import proRecruitedServiceAddons from './reducers/proRecruitedServiceAddons'
import vipPros from './reducers/vipPros'
import marketSelectedVipPros from './reducers/marketSelectedVipPros'
import selectVipProStatus from './reducers/selectVipProStatus'
import managerInfo from './reducers/managerInfo'
import managerAuthStatus from './reducers/managerAuthStatus'
import laborerComplaintDetails from './reducers/laborerComplaintDetails'
import sendLaborerReport from './reducers/sendLaborerReport'
import cancelLaborerReport from './reducers/cancelLaborerReport'
import languageModal from './reducers/languageModal'
import laborerSupportTickets from './reducers/laborerSupportTickets'
import laborerCreationTicket from './reducers/laborerCreationTicket'
import laborerSupportAttachments from './reducers/laborerSupportAttachments'
import checkLaborerHasReport from './reducers/checkLaborerHasReport'
import laborerListingRequests from './reducers/laborerListingRequests'
import acceptLaborerListingRequest from './reducers/acceptLaborerListingRequest'
import rejectLaborerListingRequest from './reducers/rejectLaborerListingRequest'
import listLaborerEligibility from './reducers/listLaborerEligibility'
import listLaborerCV from './reducers/listLaborerCV'
import cancelListingLaborerCV from './reducers/cancelListingLaborerCV'
import listingLaborerCVRequest from './reducers/listingLaborerCVRequest'
import listLaborerFilter from './reducers/listLaborerFilter'
import listingCVsList from './reducers/listingCVsList'
import selectListingCV from './reducers/selectListingCV'
import listingRequestList from './reducers/listingRequestList'
import listingRequest from './reducers/listingRequest'
import submitEditListingRequest from './reducers/submitEditListingRequest'
import extendListingRequest from './reducers/extendListingRequest'
import resubmitListingRequest from './reducers/resubmitListingRequest'
import disableListingRequest from './reducers/disableListingRequest'
import acceptListingOffer from './reducers/acceptListingOffer'
import rejectListingOffer from './reducers/rejectListingOffer'
import listingOffer from './reducers/listingOffer'
import laborerInterviewFlow from './reducers/laborerInterviewFlow'
import laborerInterviewPros from './reducers/laborerInterviewPros'
import uploadPassportCopy from './reducers/uploadPassportCopy'
import acceptLaborerEditListingRequest from './reducers/acceptLaborerEditListingRequest'
import rejectLaborerEditListingRequest from './reducers/rejectLaborerEditListingRequest'
import createInterview from './reducers/createInterview'
import payForInterviewStatus from './reducers/payForInterviewStatus'
import interviewsList from './reducers/interviewsList'
import interview from './reducers/interview'
import interviewPdfReport from './reducers/interviewPdfReport'
import interviewBill from './reducers/interviewBill'
import submitOfflinePayment from './reducers/submitOfflinePayment'
import laborerTransactionsList from './reducers/laborerTransactionsList'
import laborerTransactionDetails from './reducers/laborerTransactionDetails'
import acceptSalaryPayment from './reducers/acceptSalaryPayment'
import rejectSalaryPayment from './reducers/rejectSalaryPayment'
import employerLaborersList from './reducers/employerLaborersList'
import kabiServiceBars from './reducers/kabiServiceBars'
import interviewResult from './reducers/interviewResult'
import employerLaborerDetails from './reducers/employerLaborerDetails'
import getPassportCopy from './reducers/getPassportCopy'
import checkDirectTransfersCount from './reducers/checkDirectTransfersCount'
import checkItoITransfersCount from './reducers/checkItoITransfersCount'
import specialServicesList from './reducers/specialServicesList'

const appReducers = {
  acceptContractStatus,
  acceptEToIOfferStatus,
  acceptFTRequestStatus,
  acceptIToIOfferStatus,
  acceptLaborerTransferRequestStatus,
  acceptOfferRequestStatus,
  accessibilityTools,
  addComplaintMissingAttachmentStatus,
  agriculturalRegisterationFile,
  agriculturalSubmission,
  allMarketOffers,
  alternativeVisaEligibility,
  alternativeVisaFlow,
  arrivalCities,
  auth,
  authBanner,
  authFlow,
  availableVisas,
  banksInfo,
  banksList,
  cancelContractAuthRequestStatus,
  cancelContractStatus,
  cancelEToIContractStatus,
  cancelFTRequestStatus,
  cancelRequestStatus,
  cancelReportRunawayLaborerStatus,
  changePasswordStatus,
  checkCaptchaStatus,
  checkFeesExemption,
  checkNafath,
  complaintInquiryStatus,
  complaintsRating,
  complaintsRatingV2,
  contractAuthDetailedRequest,
  contractAuthEmployerLaborers,
  contractAuthRequestsList,
  contractInfo,
  contractRequestInfo,
  contractRequestsList,
  createAgent,
  createComplaintStatus,
  createContractAuthRequest,
  createContractAuthRequestStatus,
  createNoticeStatus,
  createRefundTicketStatus,
  cvByPreferences,
  cvReportStatus,
  disabilityVIsaEligibility,
  disabilityVisaFlow,
  domesticTransferCreating,
  domesticTransferForm,
  downloadHistory,
  eligibleWorkers,
  eTawtheeqContractStatus,
  eToICancelReplacementStatus,
  eToIFinancialDocuments,
  eToILegacyRequests,
  eToIReplaceWorkerStatus,
  eToIRequest,
  eToIRequests,
  eToITransferWorkerStatus,
  extendContractStatus,
  farmerTransfer,
  farmerTransferability,
  farmerTransferEligibility,
  farmerVisaCancelationStatus,
  farmerVisaEligibility,
  farmerVisaFlow,
  farmerVisaRequests,
  getEligibleLaborers,
  infoAboutFarmerRequest,
  issueNewDisabilityVisaStatus,
  issueNewFarmerTransferStatus,
  issueNewFarmerVisaStatus,
  issueNewVisaRequestStatus,
  isUserHasVisas,
  iToERequestActions,
  iToERequestDetails,
  iToERequests,
  iToIBill,
  iToIFinancialDocuments,
  iToIInvoiceFile,
  iToILaborerDetails,
  iToILaborerList,
  iToIRequestDetails,
  iToIRequestsList,
  iToITransferEligibility,
  iToITransferRequestStatus,
  iToISuggestedCost,
  laborerInfo,
  laborerIToIRelativeInfo,
  laborerLoginStatus,
  laborerOtpVerifyStatus,
  laborerPhoneForContractAuthStatus,
  laborerRefreshToken,
  laborerTransferDetails,
  laborerTransfersList,
  laborOffices,
  labors,
  lang,
  lastFourVisas,
  locationDetails,
  loginNafath,
  cookiesDisclaimerBar,
  maarofaBar,
  maarofaOffices,
  maarofaRequest,
  maarofaRequestStatus,
  maarofaServiceTemplate,
  marketFilter,
  marketOffers,
  marketSelectedOffers,
  mobileVerify,
  mobileVerifyStatus,
  najmRedirectionUrlStatus,
  nationalities,
  newNoticeFlow,
  notice,
  noticeAvailability,
  notices,
  occupationDescriptions,
  occupations,
  officeInfo,
  onlinePaymentVisa,
  outdatedIBAN,
  payForEContractStatus,
  payForEToIRequestStatus,
  payForFarmerTransferStatus,
  payForFarmerVisaStatus,
  payForIToIRequestStatus,
  payNotice,
  paymentInfo,
  preferencesForm,
  preselectedForm,
  priceRange,
  proServiceDetails,
  prosStatistic,
  recruitmentDurationRange,
  recruitmentFilter,
  refundDetails,
  refundPayFort,
  refundSADAD,
  refundableBills,
  regularVisaFlow,
  rejectContractStatus,
  rejectEToIOfferStatus,
  rejectFTRequestStatus,
  rejectLaborerTransferRequestStatus,
  rejectOfferStatus,
  rejectionReasonsList,
  rejectIToIOfferStatus,
  replaceVisasStatus,
  replaceableVisa,
  reportRunawayLaborerStatus,
  requestIdForContractIdStatus,
  resendEToIRequestStatus,
  respondToComplaintSolutionStatus,
  respondToOfficeComplaintStatus,
  runawaysList,
  saveUserProfileStatus,
  sendAbsherUpdateMobileStatus,
  sendLaborerRelativeInfo,
  sendOtpUpdateMobileStatus,
  setNewPrimaryAddress,
  showComplaintsSurvey,
  skills,
  submitFeesExemptionStatus,
  submitWataniSurveyStatus,
  supportTicket,
  supportTickets,
  supportTicketsGuest,
  surveyQuestions,
  ticket,
  ticketsList,
  updateLaborsStatus,
  updateNationalAddress,
  uploadDisabilityProofStatus,
  uploadVisasFilesStatus,
  userVisaEligibility,
  vatInfo,
  verifyAbsherUpdateMobileStatus,
  verifyAgent,
  verifyOtpUpdateMobileStatus,
  visaCancellationStatus,
  visaInfo,
  visaInquiryStatus,
  visaInvoiceFile,
  visaIssuePlaces,
  visaIssueRequestInfo,
  visaIssueRequestsList,
  visaWithContractRequestStatus,
  visasList,
  visaPdfUrl,
  workers,
  wpsCommitmentsStatistic,
  wpsCurrentLaborer,
  wpsCurrentTransaction,
  wpsLaborersList,
  wpsLaborerTransactions,
  removeFromPilotList,
  contractAuthAppendix,
  createContractAuthAppendixStatus,
  updateLaborerMobileNumberStatus,
  eToIInvoiceFile,
  cancelContractAuthAppendixStatus,
  proServiceAddons,
  proServiceAddonsAll,
  contractInvoiceFile,
  proPerformance,
  proPerformanceOverall,
  proPerformanceFilter,
  acceptanceRates,
  laborerAuthRequestList,
  laborerAuthRequestDetails,
  laborerAuthContractText,
  acceptLaborerAuthRequestStatus,
  rejectLaborerAuthRequestStatus,
  authRejectionReasonList,
  laborerAuthAppendixList,
  laborerAuthAppendixDetails,
  rejectLaborerAuthAppendix,
  approveLaborerAuthAppendix,
  removeAgentStatus,
  vipRecruitmentFilter,
  vipPreselectedInfo,
  simpleAuthContractInfo,
  authContractDraft,
  authLaborerContractPdf,
  authContractPdf,
  authContract,
  vipContractRequest,
  authPendingAppendix,
  authPendingLaborerAppendix,
  tameeniRedirectionUrlStatus,
  delayedContractCounts,
  vipVisaList,
  proRecruitedServiceAddons,
  vipPros,
  marketSelectedVipPros,
  selectVipProStatus,
  managerInfo,
  managerAuthStatus,
  laborerComplaintDetails,
  sendLaborerReport,
  cancelLaborerReport,
  languageModal,
  laborerSupportTickets,
  laborerCreationTicket,
  laborerSupportAttachments,
  checkLaborerHasReport,
  laborerListingRequests,
  acceptLaborerListingRequest,
  rejectLaborerListingRequest,
  listLaborerEligibility,
  listLaborerCV,
  cancelListingLaborerCV,
  listingLaborerCVRequest,
  listLaborerFilter,
  listingCVsList,
  selectListingCV,
  listingRequestList,
  listingRequest,
  submitEditListingRequest,
  extendListingRequest,
  resubmitListingRequest,
  disableListingRequest,
  acceptListingOffer,
  rejectListingOffer,
  listingOffer,
  laborerInterviewFlow,
  laborerInterviewPros,
  uploadPassportCopy,
  acceptLaborerEditListingRequest,
  rejectLaborerEditListingRequest,
  createInterview,
  payForInterviewStatus,
  interviewsList,
  interview,
  interviewPdfReport,
  interviewBill,
  submitOfflinePayment,
  laborerTransactionsList,
  laborerTransactionDetails,
  acceptSalaryPayment,
  rejectSalaryPayment,
  employerLaborersList,
  employerLaborerDetails,
  kabiServiceBars,
  interviewResult,
  getPassportCopy,
  checkDirectTransfersCount,
  checkItoITransfersCount,
  specialServicesList,
}

type AppReducer = typeof appReducers
type AppReducerKey = keyof AppReducer

const reducersProcessing = (reducers: AppReducer): AppReducer => {
  const setReducer =
    (reducer: Function, reducerKey: AppReducerKey) =>
    (state: unknown, action: { type: string; payload: Set<keyof RootState> | null }) => {
      const { payload, type } = action
      if (type === Actions.RESET_STATE && payload && payload.has(reducerKey)) {
        return reducer(undefined, action)
      }

      if (type === Actions.RESET_STATE && !payload) return reducer(undefined, action)

      return reducer(state, action)
    }
  return (Object.keys(appReducers) as Array<AppReducerKey>).reduce(
    (acc, curr) => ({ ...acc, [curr]: setReducer(reducers[curr], curr) }),
    {} as AppReducer,
  )
}

const rootReducer = () => combineReducers(reducersProcessing(appReducers))

export type RootState = ReturnType<ReturnType<typeof rootReducer>>

export default rootReducer
